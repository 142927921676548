<template>
  <van-pull-refresh v-model="isRefreshing" @refresh="onRefresh" style="height: 100%;width: 100%;overflow-y: auto;">
    <template v-if="detail">
      <div class="padding-08">
        <div class="padding-08 bold fc-t">
          <span>{{detail.project ? detail.project.proName : ''}}</span>
          <br />
          <span class="fs-huge">{{ detail.stageName }}</span>
        </div>
        <div class="padding-08 fc-c">{{ detail.stageTarget || '暂无阶段目标' }}</div>
      </div>
      <van-cell-group title="阶段概况">
        <van-cell title="阶段进度">
          <div class="h c">
            <van-progress color="#607ae3" :percentage="detail.progress*100" :show-pivot="false" class="flex" />
            <span class="no-flex fs-small gap-l-small">{{Math.round(detail.progress*10000) / 100}}%</span>
          </div>
        </van-cell>
        <van-cell title="阶段状态">
          <van-tag v-if="detail.status == 'WAITTING'">待开始</van-tag>
          <van-tag type="primary" v-else-if="detail.status == 'RUNING'">进行中</van-tag>
          <van-tag type="success" v-else-if="detail.status == 'FINISH'">已完成</van-tag>
          <van-tag type="danger" v-else-if="detail.status == 'CANCEL'">已取消</van-tag>
        </van-cell>
      </van-cell-group>
      <van-cell-group title="阶段任务">
        <template v-if="taskList.length">
          <task-item v-for="t in taskList" :key="t.id" :task="t" />
        </template>
        <van-empty v-else description="暂无任务信息" />
      </van-cell-group>
    </template>
  </van-pull-refresh>
</template>
  
<script>
import { getTask } from "@/api/project/task";
import { getStageById } from "@/api/project/stage";
import TaskItem from "../task/item";

export default {
  components: { TaskItem },
  data() {
    return {
      detail: null,
      taskList: [],
      projectId: null,
      stageName: null,
      stageId: null,
      isRefreshing: false,
      projectTaskStatusEnum: {
        WAITTING: { id: 0, key: "WAITTING", name: "待开始", type: "info" },
        RUNING: { id: 1, key: "RUNING", name: "进行中", type: "primary" },
        FINISH: { id: 2, key: "FINISH", name: "已完成", type: "success" },
        CANCEL: { id: 3, key: "CANCEL", name: "已取消", type: "danger" },
      },
      typeEnum: {
        info: "fc-g",
        primary: "fc-p",
        success: "fc-s",
        danger: "fc-e",
      },
    };
  },
  mounted() {
    this.stageId = this.$route.params.stageId;
    this.getStageDetail();
  },
  methods: {
    onRefresh() {
      // 下拉刷新数据
      this.taskList = [];
      this.getStageDetail();
      this.isRefreshing = false;
    },
    getStageDetail() {
      getStageById(this.stageId).then((res) => {
        this.detail = res;
        this.projectId = res.proId;
        this.getTaskList();
      });
    },
    getTaskList() {
      getTask({ proId: this.projectId, stageId: this.stageId }).then((res) => {
        this.taskList = res || [];
      });
    },
  },
};
</script>