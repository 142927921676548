<template>
  <van-cell is-link :to="`/project/task/detail/${task.id}`" v-if="task">
    <template slot="title">
      <span class="bold flex fs-large fc-t">{{task.name}}</span>
    </template>
    <template #label>
      <div class="bold fs-mini">{{task.proName}} / {{task.stageName}}</div>
      <div class="fc-c gap-10 van-multi-ellipsis--l2">{{task.info}}</div>
      <div class="h c gap-10">
        <van-tag :type="status[task.status].type" class="no-flex">{{status[task.status].name}}</van-tag>
        <div class="flex"></div>
        <van-progress :percentage="task.progress * 100" :show-pivot="false" class="no-flex" style="width: 25vw;" />
        <span class="no-flex fs-mini" style="width: 4rem;">&nbsp;&nbsp;{{task.progressStr}}</span>
      </div>
    </template>
  </van-cell>
</template>

<script>
export default {
  props: {
    task: Object,
  },
  data() {
    return {
      status: {
        WAITTING: { id: 0, key: "WAITTING", name: "待开始", type: "default" },
        RUNING: { id: 1, key: "RUNING", name: "进行中", type: "primary" },
        FINISH: { id: 2, key: "FINISH", name: "已完成", type: "success" },
        CANCEL: { id: 3, key: "CANCEL", name: "已取消", type: "danger" },
      },
    };
  },
};
</script>